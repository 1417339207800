<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('图片管理')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="4" style="text-align:right;">
      <el-button type="success" size="small" icon="el-icon-refresh" @click="refresh()" circle></el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10" type="flex" justify="space-between" align="center" class="ctrl-wrap">
    <el-col :span="18">
      <el-upload :action="uploadUrl"
        :show-file-list="false"
        :with-credentials="true"
        :on-success="handleAvatarSuccess"
      >
        <el-button type="primary" size="mini">
            <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
            <transition name="el-fade-in-linear">
              <span v-show="!isMinScreen">{{msg('上传文件')}}</span>
            </transition>
        </el-button>
      </el-upload>
    </el-col>
    <el-col :span="6" style="text-align:right;">
      <el-button type="danger" size="mini" @click="deleted()">
        <i class="el-icon-delete" style="display:inline-block;margin-right:4px;"></i>
        <transition name="el-fade-in-linear">
          <span v-show="!isMinScreen">{{msg('删除')}}</span>
        </transition>
      </el-button>
    </el-col>
  </el-row>
  
  <div class="table-wrap">
    <el-table :data="attachments" style="width: 100%" :height="docHeight" size="mini" ref="dataTable" stripe>
      <el-table-column type="selection"  min-width="55" :fixed="!isMinScreen"></el-table-column>
      <el-table-column prop="id" label="ID" min-width="70" :fixed="!isMinScreen"></el-table-column>
      <el-table-column :label="msg('路径')" min-width="120">
        <template slot-scope="scope">
          <el-image style="width: 32px; height: 32px;"
              :src="imgBaseUrl+scope.row.id" 
              :preview-src-list="[imgBaseUrl+scope.row.id]">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="filename" :label="msg('文件名')" min-width="120"></el-table-column>
      <el-table-column prop="created" :label="msg('创建时间')" min-width="120"></el-table-column>
      <el-table-column prop="remark" :label="msg('说明')" min-width="120"></el-table-column>
    </el-table>

    <el-pagination id="xpagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :background="true"
      :current-page="currentPage"
      :page-sizes="[10, 20,50, 100, 200, 300, 400]"
      :page-size="query.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</div>
</template> 

<script>
// @ is an alias to /src
export default {
  name: 'role',
  components: {
  },
  mounted:function(){
    this.getAttachments();
  },
  data(){
    return{
      query:{  //查询条件
        start: 0,
        length: 10,
      },
      currentPage: 1,   //分页的当前页
      attachments: [],   //表格数据列表
      total: 0,//总记录数,

      imgBaseUrl: this.$kit.server+"/api/p/img?id=",
      uploadUrl: this.$kit.api.upload,
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
      get() {
        return this.$store.state.isMinScreen;
      }
    },
    docHeight: {  //表格的高度
      get() {
        var height =  this.$store.state.docHeight;
        return height - 250;
      }
    }
  },
  methods: {
    getAttachments: function(start){ //获取列表
      var _this = this;

      var param = this.$kit.rebuildQuery(this.query);
      if(start){
        param.start = 0;
      }

      this.axios.post(this.$kit.api.attachmentList, param).then(function (result) {
        if(result.status){
          if(_this.attachments.length>0)
            _this.attachments.splice(0, _this.attachments.length);
          
          _this.total = result.data.recordsTotal;
          var roleDatas = result.data.data;

          for(var i=0; i<roleDatas.length; i++){
            var item = roleDatas[i];
            if(item.created){
              item.created = item.created.substring(0,10);
            }

            _this.attachments.push(item);
          } 

        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },//end function
    refresh: function(){  //刷新列表
      this.$kit.clearQuery(this.query);
      this.currentPage = 1;
      this.getAttachments();
    },
    handleSizeChange(val) {  //分页的每页数据变化后的回调事件
      //console.log(`每页 ${val} 条`);
      this.query.length = val;
      this.query.start = 0;
      this.getAttachments();
    },
    handleCurrentChange(val) {   //分页的当前页改变后的回调事件
      //console.log(`当前页: ${val}`);
      this.query.start = this.query.length * (val -1);
      this.getAttachments();
    },
    handleAvatarSuccess(res, file,fileList) {
      this.$message(res.msg);
      //console.log(res , "11", file);
      if(res.status){
          //this.category.icon = res.data;
        this.refresh();
      }
    },
    deleted(){  //删除
      var selectes = this.$refs.dataTable.selection;
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      var row = selectes[0];
      var _this = this;

      this.$confirm(this.msg('确定要删除选定图片吗?'), this.msg('提示'), {
          confirmButtonText: this.msg('确定'),
          cancelButtonText: this.msg('取消'),
          type: 'warning'
        }).then(() => {
          _this.$axios.get(_this.$kit.api.attachmentDelete+"?id="+row.id)
            .then(function(result){
              _this.$message(result.msg || _this.msg('删除成功'));
              _this.getAttachments();
          });
        });
    },
  }
}
</script>

<style class="scss" scoped>


</style>
